import axios from "@/api/axios"; // 引用axios

export const dataList = (params) => {
    return axios({
        url: "/api/protect/protectMediaLibListData",
        method: "post",
        params: params,
    });
};


export const transferVoice = (params) => {
    return axios({
        url: "/api/protect/protectGetStringTransferVoice",
        method: "post",
        params: params,
    });
};

export const dataMove = (params) => {
    return axios({
        url: "/api/protect/mediaDel",
        method: "post",
        params: params,
    });
};

export const dataPass = (params) => {
    return axios({
        url: "/api/protect/pass",
        method: "post",
        params: params,
    });
};

export const dataNopass = (params) => {
    return axios({
        url: "/api/protect/noPassEdit",
        method: "post",
        params: params,
    });
};