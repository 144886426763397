<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="名称">
            <el-input v-model="searchForm.display_name" placeholder="请输入 名称"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="审核名称">
            <el-select v-model="searchForm.status" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </template>

      <template #BTN>
        <el-button @click="dialogVisible = true" class="searchBtn">新增</el-button>
      </template>
    </Searchbar>

    <basicTable
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        :total="page.total">
    </basicTable>

    <el-dialog title="新增" v-model="dialogVisible" width="30%" :before-close="handleClose">
      <el-form ref="demoform" label-position="right" label-width="120px" :model="form">
        <el-form-item label="名称" :rules="[{ required: true, message: '名称' }]" prop="displayName">
          <el-input v-model="form.displayName" />
        </el-form-item>
        <el-form-item label="文转语文字" :rules="[{ required: true, message: '文转语文字' }]" prop="text">
          <el-input v-model="form.text" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="trans" >提交</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog title="审核" v-model="shVisible" width="30%" :before-close="handleClose">
      <el-form ref="formRef" label-position="right" label-width="150px" :model="shform">
        <el-form-item label="审核方联系电话" :rules="[{ required: true, message: '审核方联系电话' }]" prop="check_phone">
          <el-input v-model="shform.check_phone" />
        </el-form-item>
        <el-form-item label="审核意见" :rules="[{ required: true, message: '审核意见' }]" prop="check_opinion">
          <el-input v-model="shform.check_opinion" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="sh" >提交</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { dataList, transferVoice, dataMove, dataPass, dataNopass } from "@/api/fireproof/phonetics";
import Searchbar from "@/components/Searchbar";
import {nextTick, onMounted, reactive, ref, toRefs} from "vue";
import {ElMessage} from "element-plus";
export default {
  components:{
    Searchbar
  },
  setup(){
    let demoform = ref(null);
    let formRef = ref(null);
    let idx = ref("");
    let dialogVisible = ref(false);
    let shVisible = ref(false);
    let state = reactive({
      shform:{},
      options:[
        {
          value: '1',
          label: '待审核'
        },
        {
          value: '2',
          label: '审核通过'
        },
        {
          value: '3',
          label: '审核不通过'
        }
      ],
      searchForm: {},
      form: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      attrList: [
        {
          prop: "display_name",
          label: "名称",
          render: (row) => <div>{row.display_name}</div>
        },
        {
          prop: "time_length",
          label: "时长",
          render: (row) => <div>{row.time_length}</div>
        },
        {
          prop: "resource_text",
          label: "原文字",
          render: (row) => <div>{row.resource_text}</div>
        },
        {
          width:"330px",
          prop: "file_name",
          label: "播放",
          render: (row) => <audio controls src={row.file_name}></audio>
        },
        {
          prop: "status",
          label: "状态",
          render: (row) => <div>{row.status == 1 ? '待审核' : row.status == 2 ? "审核通过" : "审核不通过"}</div>
        },
        {
          prop: "create_time",
          label: "时间",
          render: (row) => <div>{row.create_time}</div>
        },
        {
          prop: "",
          label: "操作",
          render: (row) =>{
            if(row.status == 1){
              return <div>
                <el-button type="text" icon="View" onClick={() => dataDel(row)}>删除</el-button>
                <el-button type="text" icon="Compass" onClick={() => pass(row)}>通过</el-button>
                <el-button type="text" icon="CloseBold" onClick={() => nopass(row)}>不通过</el-button>
              </div>
            }else{
              return <el-button type="text" icon="View" onClick={() => dataDel(row)}>删除</el-button>
            }
          }
        }
      ]
    })

    function onSearch() {
      state.page.current = 1;
      onLoad();
    }

    function resetForm() {
      state.page.current = 1;
      state.searchForm = {};
      onLoad();
    }

    onMounted(()=>{
      onLoad();
    })

    function trans(){
      demoform.value.validate((valid) => {
        if (valid) {
          let { displayName, text } = state.form;
          transferVoice({ displayName, text }).then(res=>{
            if(res.data.success){
              ElMessage({message: res.data.code.message, type: 'success'});
              state.page.current = 1;
              state.searchForm = {};
              dialogVisible.value = false;
              onLoad();
            }
          })
        } else {
          return false
        }
      })

    }

    function pass(row){
      dataPass({ id:row.id }).then(res=>{
        if(res.data.success){
          ElMessage({message: res.data.code.message, type: 'success'});
          onLoad();
        }
      })
    }

    function nopass(row){
      idx.value = row.id;
      shVisible.value = true;
    }

    function extractHostname(url) {
      var hostname;
      if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
      } else {
        hostname = url.split('/')[0];
      }
      hostname = hostname.split(':')[0];
      hostname = hostname.split('?')[0];
      return hostname;
    }

    function onLoad() {
      dataList({ limit: state.page.limit, page: state.page.current, ...state.searchForm }).then(res => {
        state.tableData = res.data.data.list;
        let url = window.location.hostname;
        state.tableData.forEach((item, index)=>{
          state.tableData[index].file_name = item.file_name.replace(extractHostname(item.file_name), url);
        })
        state.page.total = res.data.data.count;
      });
    }

    function dataDel(row){
      dataMove({ id:row.id }).then(res=>{
        if(res.data.success){
          ElMessage({message: res.data.code.message, type: 'success'});
          state.page.current = 1;
          onLoad();
        }
      })
    }

    function handleClose(done){
      done();
      nextTick(()=>{
        formRef.value.resetFields();
        demoform.value.resetFields();
      })
    }

    function sh(){
      formRef.value.validate((valid) => {
        if (valid) {
          let { check_opinion, check_phone } = state.shform;
          dataNopass({ id: idx.value, check_opinion, check_phone }).then(res=>{
            if(res.data.success){
              ElMessage({message: res.data.code.message, type: 'success'});
              shVisible.value = false;
              onLoad();
            }
          })
        } else {
          return false
        }
      })
    }

    function handleCurrentChange(currentPage) {
      state.page.current = currentPage;
      onLoad();
    }

    function handleSizeChange(pageSize) {
      state.page.limit = pageSize;
      onLoad();
    }

    return{
      handleCurrentChange,
      handleSizeChange,
      handleClose,
      trans,
      idx,
      demoform,
      formRef,
      shVisible,
      dialogVisible,
      sh,
      onSearch,
      resetForm,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>